import { FC, memo, useState, FormEvent } from 'react';
import { Flexbox, Text, Icon, Button, Input, Container, Link } from '@morf/ui';
import { GoogleLogin } from './GoogleLogin';
import { styled, useTheme } from '@morf/theming';
import { useUrlParams } from '../helpers/useUrlParams';
import { useStytch } from '@stytch/nextjs';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

const Login: FC = () => {
  const theme = useTheme();
  const { from } = useUrlParams();
  const stytch = useStytch();
  const router = useRouter();
  const SESSION_DURATION = 60;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleEmailLogin = async (e: FormEvent) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);
    setError('');

    try {
      const response = await stytch.passwords.authenticate({
        email,
        password,
        session_duration_minutes: SESSION_DURATION,
      });

      if (response?.user_id) {
        toast.success('Login successful!');

        const origin = () => {
          if (typeof window !== 'undefined') {
            return window.location.origin || '';
          }
          return null;
        };

        const domain = origin();
        const domainLink = domain ? `${domain}/authenticate` : '/';
        const redirectLink = from ? `?from=${encodeURIComponent(from)}` : '';

        const redirectPath = domainLink + redirectLink;
        router.push(redirectPath);
      } else {
        throw new Error('Login failed. Please check your credentials.');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Invalid email or password. Please try again.');
      setLoading(false);
    }
  };

  return (
    <Flexbox
      backgroundColor='white'
      justifyContent='center'
      alignItems='center'
      height='100vh'
    >
      <AuthCard
        backgroundColor={theme.colors.ui.card}
        direction='column'
        justifyContent='center'
        alignItems='center'
        position='relative'
        width='auto'
        height='auto'
        pt={4}
      >
        <Text tag='h1'>Sign in</Text>
        <Container width='100%' p={1}>
          <GoogleLogin from={from} />

          <Flexbox
            alignItems='center'
            gap={1}
            height='fit-content'
            width='100%'
          >
            <Divider />
            <Text tag='p2' color={theme.colors.text.muted}>
              or
            </Text>
            <Divider />
          </Flexbox>

          <form onSubmit={handleEmailLogin}>
            {error && (
              <ErrorMessage>
                <Icon
                  name='warning'
                  stroke={theme.colors.support.red.dark}
                  size={1}
                />
                <Text tag='p2' color={theme.colors.support.red.dark}>
                  {error}
                </Text>
              </ErrorMessage>
            )}

            <Flexbox direction='column' gap={1} mb={1}>
              <Container>
                <Flexbox pb={0.25}>
                  <Text tag='p2' weight='medium'>
                    Email
                  </Text>
                </Flexbox>
                <Input
                  id='email'
                  type='email'
                  value={email}
                  height='fit-content'
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Enter your email'
                  required
                />
              </Container>

              <Container>
                <Flexbox pb={0.25}>
                  <Text tag='p2' weight='medium'>
                    Password
                  </Text>
                </Flexbox>
                <Input
                  id='password'
                  type='password'
                  value={password}
                  height='fit-content'
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder='Enter your password'
                  required
                />
              </Container>
            </Flexbox>

            <StyledButton
              variant='custom'
              backgroundColor={theme.colors.main.dark.darker}
              borderColor={theme.colors.ui.divider}
              width='100%'
              height='2.25rem'
              color='white'
              type='submit'
              disabled={loading}
            >
              <Text tag='p2' weight='medium' color='white'>
                {loading ? 'Logging in...' : 'Login with Email'}
              </Text>
            </StyledButton>
            <Button
              variant='tertiary'
              width='100%'
              height='2.25rem'
              disabled={loading}
            >
              <Link href='/signup'>
                <Text tag='p2' weight='medium'>
                  Create Free Account
                </Text>
              </Link>
            </Button>
          </form>
        </Container>
      </AuthCard>
    </Flexbox>
  );
};

export const MemoizedLogin = memo(Login);

const AuthCard = styled(Flexbox)`
  min-width: 25rem;
  border-radius: ${({ theme }) => theme.borderRadius[0.5]};

  @media (max-width: 768px) {
    min-width: auto;
    width: 90%;
    max-width: 25rem;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.support.red.lightest};
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
`;

const Divider = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.ui.divider};
`;

const StyledButton = styled(Button)`
  border-color: transparent;
  margin-bottom: 1rem;
`;
