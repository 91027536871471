import { styled } from '@morf/theming';
import { Container } from '@morf/ui';

export const StyledGoogleLoginContainer = styled(Container)`
  .sc-iTONeN {
    display: none;
  }
  .iddQmu {
    padding: 0;
  }
`;
